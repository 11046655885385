import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Link,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

export default function Hero({
  title,
  subtitle,
  image,
  video,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column", md: "row" }}
      wrap="no-wrap"
      minH={{ sm: "35vh", md: "60vh" }}
      {...rest}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "30%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
        >
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          <ReactMarkdown>{subtitle}</ReactMarkdown>
        </Heading>
        <Link href={ctaLink}>
          <Button
            colorScheme="primary"
            rounded="md"
            py="4"
            px="4"
            lineHeight="1"
            size="md"
          >
            {ctaText}
          </Button>
        </Link>
      </Stack>
      {video && (
        <Box
          as="iframe"
          m={{ base: 12, md: 0 }}
          maxWidth="90%"
          src={`https://www.youtube.com/embed/${video}`}
          title="Derrubando xigantes"
          width="700px"
          height="450px"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      {image && (
        <Box w={{ base: "80%", md: "40%" }} mb={{ base: 12, md: 0 }}>
          <Image src={image} rounded="1rem" shadow="xl" />
        </Box>
      )}
    </Flex>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
};

Hero.defaultProps = {
  title: "React landing page with Chakra UI",
  subtitle:
    "This is the subheader section where you describe the basic benefits of your product",
  ctaText: "Create your account now",
  ctaLink: "/signup",
};
