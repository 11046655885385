import React, { useState } from "react";
import JSZip from "jszip";
import FileSaver from "file-saver";
import proj4 from "proj4";
import bladeModel from "./blade.dae";
import nacelleModel from "./nacelle.dae";
import hubModel from "./hub.dae";
import {
  HStack,
  Icon,
  Text,
  Button,
  Container,
  Link,
  Box,
} from "@chakra-ui/react";
import { MdLiveHelp } from "react-icons/md";

import TipBox from "~/src/components/tip-box";

const windmillKml = (name, longitude, latitude, hubHeight) => `<Document>
<name>${name}</name>
<StyleMap id="m_ylw-pushpin">
  <Pair>
    <key>normal</key>
    <styleUrl>#s_ylw-pushpin</styleUrl>
  </Pair>
  <Pair>
    <key>highlight</key>
    <styleUrl>#s_ylw-pushpin_hl</styleUrl>
  </Pair>
</StyleMap>
<Style id="s_ylw-pushpin">
  <IconStyle>
    <scale>1.1</scale>
    <Icon>
      <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
    </Icon>
    <hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
  </IconStyle>
</Style>
<Style id="s_ylw-pushpin_hl">
  <IconStyle>
    <scale>1.3</scale>
    <Icon>
      <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
    </Icon>
    <hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>
  </IconStyle>
</Style>
<Placemark>
  <name>blade</name>
  <LookAt>
    <longitude>${longitude}</longitude>
    <latitude>${latitude}</latitude>
    <altitude>0</altitude>
    <heading>0</heading>
    <tilt>45</tilt>
    <range>1000</range>
    <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  <styleUrl>#m_ylw-pushpin</styleUrl>
  <Model id="model_8">
    <altitudeMode>relativeToGround</altitudeMode>
    <Location>
      <longitude>${longitude}</longitude>
      <latitude>${latitude}</latitude>
      <altitude>${hubHeight}</altitude>
    </Location>
    <Orientation>
      <heading>0</heading>
      <tilt>0</tilt>
      <roll>0</roll>
    </Orientation>
    <Scale>
      <x>1</x>
      <y>1</y>
      <z>1.333333333333333</z>
    </Scale>
    <Link>
      <href>${bladeModel}</href>
    </Link>
    <ResourceMap>
    </ResourceMap>
  </Model>
</Placemark>
<Placemark>
  <name>blade</name>
  <LookAt>
    <longitude>${longitude}</longitude>
    <latitude>${latitude}</latitude>
    <altitude>0</altitude>
    <heading>0</heading>
    <tilt>45</tilt>
    <range>1000</range>
    <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  <styleUrl>#m_ylw-pushpin</styleUrl>
  <Model id="model_8">
    <altitudeMode>relativeToGround</altitudeMode>
    <Location>
      <longitude>${longitude}</longitude>
      <latitude>${latitude}</latitude>
      <altitude>${hubHeight}</altitude>
    </Location>
    <Orientation>
      <heading>0</heading>
      <tilt>0</tilt>
      <roll>${hubHeight}</roll>
    </Orientation>
    <Scale>
      <x>1</x>
      <y>1</y>
      <z>1.333333333333333</z>
    </Scale>
    <Link>
      <href>${bladeModel}</href>
    </Link>
    <ResourceMap>
    </ResourceMap>
  </Model>
</Placemark>
<Placemark>
  <name>blade</name>
  <LookAt>
    <longitude>${longitude}</longitude>
    <latitude>${latitude}</latitude>
    <altitude>0</altitude>
    <heading>0</heading>
    <tilt>45</tilt>
    <range>1000</range>
    <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  <styleUrl>#m_ylw-pushpin</styleUrl>
  <Model id="model_8">
    <altitudeMode>relativeToGround</altitudeMode>
    <Location>
      <longitude>${longitude}</longitude>
      <latitude>${latitude}</latitude>
      <altitude>${hubHeight}</altitude>
    </Location>
    <Orientation>
      <heading>0</heading>
      <tilt>0</tilt>
      <roll>240</roll>
    </Orientation>
    <Scale>
      <x>1</x>
      <y>1</y>
      <z>1.333333333333333</z>
    </Scale>
    <Link>
      <href>${bladeModel}</href>
    </Link>
    <ResourceMap>
    </ResourceMap>
  </Model>
</Placemark>
<Placemark>
  <name>hub</name>
  <LookAt>
    <longitude>${longitude}</longitude>
    <latitude>${latitude}</latitude>
    <altitude>0</altitude>
    <heading>0</heading>
    <tilt>45</tilt>
    <range>1000</range>
    <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  <styleUrl>#m_ylw-pushpin</styleUrl>
  <Model id="model_7">
    <altitudeMode>relativeToGround</altitudeMode>
    <Location>
      <longitude>${longitude}</longitude>
      <latitude>${latitude}</latitude>
      <altitude>0</altitude>
    </Location>
    <Orientation>
      <heading>0</heading>
      <tilt>0</tilt>
      <roll>0</roll>
    </Orientation>
    <Scale>
      <x>1</x>
      <y>1</y>
      <z>${hubHeight}</z>
    </Scale>
    <Link>
      <href>${hubModel}</href>
    </Link>
    <ResourceMap>
    </ResourceMap>
  </Model>
</Placemark>
<Placemark>
  <name>nacelle</name>
  <LookAt>
    <longitude>${longitude}</longitude>
    <latitude>${latitude}</latitude>
    <altitude>0</altitude>
    <heading>0</heading>
    <tilt>45</tilt>
    <range>1000</range>
    <altitudeMode>relativeToGround</altitudeMode>
  </LookAt>
  <styleUrl>#m_ylw-pushpin</styleUrl>
  <Model id="model_4">
    <altitudeMode>relativeToGround</altitudeMode>
    <Location>
      <longitude>${longitude}</longitude>
      <latitude>${latitude}</latitude>
      <altitude>${hubHeight}</altitude>
    </Location>
    <Orientation>
      <heading>0</heading>
      <tilt>0</tilt>
      <roll>0</roll>
    </Orientation>
    <Scale>
      <x>1</x>
      <y>1</y>
      <z>1</z>
    </Scale>
    <Link>
      <href>${nacelleModel}</href>
    </Link>
    <ResourceMap>
    </ResourceMap>
  </Model>
</Placemark>
</Document>`;

export const NovoMapa = () => {
  const [inputPoints, setInputPoints] = useState("");
  const [errors, setErrors] = useState([]);

  // Define coordinate transformation projections
  proj4.defs("EPSG:25829", "+proj=utm +zone=29 +ellps=GRS80 +units=m +no_defs");
  proj4.defs("EPSG:4258", "+proj=longlat +ellps=GRS80 +no_defs");

  const parseCoordinate = (coord) => {
    // Convert to string if not already
    let strCoord = String(coord);

    // Remove dots
    strCoord = strCoord.replace(/\./g, "");

    // Try to parse as float and format
    const numCoord = parseFloat(strCoord);

    // Check if it's a valid number
    if (!isNaN(numCoord)) {
      return numCoord;
    }
  };

  const convertCoordinates = (hubHeight) => {
    setErrors([]);

    const points = inputPoints
      .trim()
      .split("\n")
      .filter((l) => l.length > 0);

    const kmz = new JSZip();
    let windmills = [];
    let hasErrors = false;

    points.forEach((point, index) => {
      // Split the line into parts
      const parts = point.trim().split(" ");

      // Ensure we have at least 3 parts (name/s, x, y)
      if (parts.length < 3) {
        setErrors((prevErrors) => [
          ...prevErrors,
          `Formato erróneo na liña ${index + 1}: ${point}`,
        ]);
        hasErrors = true;
        return;
      }

      // Take last two parts as coordinates
      const x = parseCoordinate(parts[parts.length - 2]);
      const y = parseCoordinate(parts[parts.length - 1]);

      // Combine all parts except the last two as the name
      const name = parts.slice(0, -2).join(" ");

      if (isNaN(x) || isNaN(y)) {
        setErrors((prevErrors) => [
          ...prevErrors,
          `Formato erróneo na liña ${index + 1}: ${point}`,
        ]);
        hasErrors = true;
        return;
      }

      const lonLatCoords = proj4("EPSG:25829", "EPSG:4258", [x, y]);
      const longitude = lonLatCoords[0].toFixed(8);
      const latitude = lonLatCoords[1].toFixed(8);

      // Generate KML content for hub, nacelle, and three blades
      windmills.push(windmillKml(name, longitude, latitude, hubHeight));
    });

    if (hasErrors) {
      return;
    }

    // Create the overall KML content with a Document element
    const kmlContent = `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
        <Document>
          ${windmills.join("\n")}
        </Document>
      </kml>`;

    // Add KML file to the KMZ archive
    kmz.file("windmills.kml", kmlContent);

    // Generate and save the KMZ file
    kmz.generateAsync({ type: "blob" }).then((content) => {
      const kmzBlob = new Blob([content], {
        type: "application/vnd.google-earth.kmz",
      });
      FileSaver.saveAs(kmzBlob, "windmills.kmz");
    });
  };

  return (
    <Container maxW="700px" mt="6">
      <Text fontSize="3xl" color="primary.600" mb="8">
        Crear un mapa con aeroxeneradores en 3D a partir dos datos do BOE/DOG
      </Text>
      <textarea
        value={inputPoints}
        onChange={(e) => setInputPoints(e.target.value)}
        placeholder={`Unha liña por aeroxenerador, como saen no DOG/BOE, por exemplo):

AE01 646.644 4.739.643
AE02 646.802,1 4.739.298,2
AE03 646.957 4.738.933
AE04 647.029,4 4.738.593,3
AE05 646957 4738933

(Da igual se van con puntos ou coma ao final... xa que no DOG pode cambiar, o importante e que o nome de cada aeroxenerador vaia en una soa palabra e cada coordenada vaia separada por un espazo, coma no exemplo)
`}
        rows={10}
      />
      {errors.length > 0 && (
        <div>
          <Text colorScheme="important" fontSize="xl">
            Revise os erros no formato de entrada:
          </Text>
          <ul>
            {errors.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
        </div>
      )}
      <br />
      <Button
        onClick={() => convertCoordinates(120)}
        fontSize="xl"
        fontWeight="normal"
        colorScheme="primary"
      >
        Xerar ficheiro KMZ
      </Button>

      <br />
      <Container maxW="700px" mt="16">
        <HStack>
          <Icon fontSize="3xl" color="cornflowerblue" as={MdLiveHelp} />
          <Text fontSize="3xl" color="cornflowerblue" mb="4">
            Necesitas axuda?
          </Text>
        </HStack>
        <Text fontSize="xl" color="cornflowerblue" mb="8">
          Aquí o explicamos en un minuto
        </Text>

        <Box
          as="iframe"
          m={{ base: 12, md: 0 }}
          maxWidth={[600, "90%"]}
          src="https://www.youtube.com/embed/4XOdP57rgZc?si=0S9M4CujB3jWAnKR"
          title="Axuda"
          width="700px"
          height="400px"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Container>
      <TipBox tipTitle="Sabías que...">
        Se aínda non asinaches o <b>Manifesto Acibal</b>, podes leelo{" "}
        <Link
          color="blue.500"
          fontWeight="bold"
          textDecoration="underline"
          href="manifesto-acibal"
        >
          aquí
        </Link>
        .
      </TipBox>
    </Container>
  );
};
