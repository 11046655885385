import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import BaseSection from "../partials/base-section";
const VideoList = ({ videoIds }) => {
  return (
    <BaseSection title="Vídeos" width="100%">
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
        {videoIds.map((id) => (
          <GridItem key={id} colSpan={{ base: 1, md: 1 }}>
            <Box
              as="iframe"
              maxWidth="100%"
              src={`https://www.youtube.com/embed/${id}`}
              title="YouTube video player"
              width="600px"
              height="350px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </GridItem>
        ))}
      </Grid>
    </BaseSection>
  );
};

export default VideoList; 
