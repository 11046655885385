import React from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import Title from "../components/partials/title";
import TipBox from "~/src/components/tip-box";

export const Map = () => (
  <>
    <Box width="100%" textAlign="center" mt={0} mb={12}>
      <Text fontSize="xl" color="primary.600" mb="8">
        Podes crear os teus propios mapas con eólicos en 3D{" "}
        <Link
          color="blue.500"
          fontWeight="bold"
          textDecoration="underline"
          href="novo-mapa"
        >
          aquí
        </Link>
      </Text>
    </Box>

    <Box width="100%" mb={32} height={{ base: "80vh", md: "600" }}>
      <Title>Polígonos Acibal, Anduriña e Zudreiro</Title>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1UU7h3CBd2olUWdglhvvvM5Jq8-vX1ks&ehbc=2E312F"
        width="100%"
        height="100%"
      ></iframe>
    </Box>
    <Box width="100%" mb={32} height={{ base: "80vh", md: "600" }}>
      <Title>Liña de Alta tensión Pico Touriñán - Tivo</Title>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=13-BNv7QSbuN8BQTx0rmebemtuQ3UgOE&ehbc=2E312F"
        width="100%"
        height="100%"
      ></iframe>
    </Box>
    <Box width="100%" mb={32} height={{ base: "80vh", md: "600" }}>
      <Title>Mapas de Campo Lameiro / Terra de Montes</Title>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1mDXXQRnbVBqJAtvqSyLTljumSBt_cvpV&ehbc=2E312F"
        width="100%"
        height="100%"
      ></iframe>
    </Box>
  </>
);
